import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/root.css';
import 'material-symbols';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import Root from './routes/root';
import ErrorPage from './error-page';
import Store from './routes/store';
import SubmitReport, {
    Intro,
    loader as tagValidator
} from './routes/report';
import InputLocation, { loader as ipLocationLoader } from './components/reporting/input-location';
import InputDescription, { loader as idLoader } from './components/reporting/input-description';
import ThankYou from './components/reporting/thank-you';
import ContactInfo from './components/reporting/input-contact-info';
import SendReportTemplate from './components/reporting/send-report-template';
import CreateReportTemplate from './components/admin/createReport';
import TemplateCreated from './components/admin/template-added';
import ReportTemplateRoot, { loader as createReportTemplateLoader } from './routes/report-template-root';
import HowTo from './routes/how-to';
import Root2 from './routes/root2';

const routerReport = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <Root />
            },
            {
                path: ':id',
                element: <Root />
            },
        ]
    },
    
    
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        
        <RouterProvider router={routerReport} />
        
    </React.StrictMode>
)
