import { Link, Outlet } from 'react-router-dom';
import '../styles/root.css'
import ProductHomepage from '../components/product-homepage';
import Logo from '../components/logo';
import Footer from '../components/footer';

export default function Root() {
    const url = window.location.href
    window.location.href = url.replace("tags","report").replace(".com/",".com/item/")
    return (
        <>
           <h2>Hello</h2>
        </>
    )
}